import { Formik } from "formik";
import { useContext, useState } from "react";
import DatePicker from "react-date-picker";
import Select from "react-select";
import * as yup from "yup";

import appStyles from "../../App.module.css";
import appConstants, {
  addDays,
  handleRedirection,
  isValidDate,
  selectControlStyling,
  selectOptionControlStyling,
  subtractYears,
} from "../../config/app-constants";
import { IQuoteForm, ISingleTripQuoteContext, ISingleTripQuoteData } from "../../config/interfaces";
import Stepper from "../Stepper";
import TooltipIcon from "../TooltipIcon";
import { SingleTripQuoteContext } from "../TripQuote";
import Footer from "../common/Footer";
import LoadingIndicator from "../common/LoadingIndicator";
import ScrollToFirstError from "../common/ScrollToFirstError";
import ShowApiError from "../common/ShowApiError";
import SvgIcon from "../common/SvgIcon";

const QuoteForm = () => {
  const {
    next,
    quoteData,
    setQuoteData,
    loader,
    setLoader,
    setPackages,
    setQuoteResult,
    destinations,
    getPackages,
    updateQuoteDataPackages,
    getQuote,
    redirection,
    getDiscountAmounts,
    setPackageAmount,
  } = useContext(SingleTripQuoteContext) as ISingleTripQuoteContext;

  const [hasError, setHasError] = useState<string | null>(null);

  const commonValidation = yup
    .date()
    .required("This field is required")
    .max(
      subtractYears(new Date(), 18),
      "Traveller must be at least 18 years old on policy start date."
    );

  const stValidation = commonValidation
    .test("stMainApplicant", "Invalid date entered.", (value) => {
      return isValidDate(value);
    })
    .when("end_date", (end_date: any, schema) => {
      if (end_date && end_date?.length && end_date[0]) {
        return schema.min(
          addDays(end_date[0], -36160),
          `Traveller must not be older than 99 years on policy end date.`
        );
      }
      return schema;
    });

  const stOtherApplicants = yup.array().when("end_date", (end_date: any, schema) => {
    if (end_date && end_date?.length && end_date[0]) {
      return schema.of(
        yup
          .date()
          .required("This field is required")
          .test("stOtherApplicant", "Invalid date entered.", (value: any) => {
            return isValidDate(value);
          })
          .max(
            subtractYears(new Date(), 1),
            "Traveller must be at least 1 years old on policy start date."
          )
          .min(
            addDays(end_date[0], -36160),
            "Traveller must not be older than 99 years on policy end date."
          )
      );
    }
    return schema.of(
      yup
        .date()
        .required("This field is required")
        .max(
          subtractYears(new Date(), 1),
          "Traveller must be at least 18 years old on policy start date."
        )
    );
  });

  const validationSchema = yup.object().shape({
    start_date: yup.date().required(),
    end_date: yup.date().required(),
    destinations: yup.array().min(1),
    main_applicant: stValidation,
    other_applicants: stOtherApplicants,
  });

  const initialFormValues: IQuoteForm = {
    start_date: quoteData.start_date,
    end_date: quoteData.end_date,
    destinations: quoteData.destinations,
    main_applicant: quoteData.main_applicant,
    other_applicants: quoteData.other_applicants,
  };

  const handleFormSubmit = async (values: IQuoteForm) => {
    try {
      setLoader("quote");

      let _quoteData: ISingleTripQuoteData = {
        ...quoteData,
        packages: null,
        all_applicants: [],
      };
      _quoteData.start_date = values.start_date;
      _quoteData.end_date = values.end_date;
      _quoteData.destinations = values.destinations;
      _quoteData.main_applicant = values.main_applicant;
      _quoteData.other_applicants = values.other_applicants;
      _quoteData.rental_vehicle_start_Date = values.start_date;
      _quoteData.rental_vehicle_end_Date = values.end_date;

      if (redirection) {
        handleRedirection(values);
        return;
      }

      const _packages = await getPackages(
        _quoteData,
        quoteData?.host_country.id,
        quoteData?.host_country.states[0]?.id
      );

      const _discount = await getDiscountAmounts(_quoteData, _packages, true);
      setPackageAmount(_discount);
      _quoteData = await updateQuoteDataPackages(_packages, _quoteData);
      const _quoteResult = await getQuote(_quoteData);
      setPackages(() => _packages);
      setQuoteResult(_quoteResult);
      setQuoteData(_quoteData);

      next();
    } catch (e: any) {
      console.log(e);
      setHasError(e.message);
    } finally {
      setLoader(null);
    }
  };

  return (
    <>
      {loader === "quote" && <LoadingIndicator />}

      <div
        className={`${
          !redirection ? `${appStyles.mainContainer} ${appStyles.W600} ${appStyles.spaceTop}` : ""
        }`}
      >
        {!redirection && <Stepper />}

        <Formik
          initialValues={initialFormValues}
          validationSchema={validationSchema}
          onSubmit={handleFormSubmit}
          enableReinitialize={true}
        >
          {({
            values,
            errors,
            touched,
            handleBlur,
            setFieldValue,
            handleSubmit,
            submitCount,
            isValid,
            validateForm,
            isSubmitting,
          }) => (
            <form
              onSubmit={async (e) => {
                e.preventDefault();
                await validateForm();
                handleSubmit();
              }}
            >
              <ScrollToFirstError submitCount={submitCount} isValid={isValid} errors={errors} />
              <div className={appStyles.pageSubTitle}>
                {!redirection && (
                  <h5 className={`${appStyles.colorPrimary} ${appStyles.mB30}`}>
                    Trip Information
                  </h5>
                )}
              </div>
              <div
                className={`${
                  redirection
                    ? appStyles.redirectionCardWrap
                    : `${appStyles.cardWrap} ${appStyles.formBox}`
                }`}
              >
                {redirection && (
                  <h5 className={`${appStyles.colorWhite} ${appStyles.mB20}`}>
                    Start Building your Custom Plan
                  </h5>
                )}
                <div className={appStyles.formWrapper}>
                  <div className={appStyles.formRow}>
                    <label className={`${appStyles.labelStyle} ${appStyles.questionWrap}`}>
                      When are you travelling?
                      <TooltipIcon text={"Your travelling dates"}></TooltipIcon>
                    </label>
                  </div>

                  <div className={appStyles.formRow}>
                    <div className={appStyles.formBlock}>
                      <div
                        className={`${
                          redirection ? appStyles.bfRedirectionFormField : appStyles.formField
                        } ${touched?.start_date && errors?.start_date ? appStyles.hasError : ""}`}
                      >
                        {!redirection ? (
                          <label className={appStyles.helperText}>Start Date</label>
                        ) : null}
                        <DatePicker
                          calendarClassName={appStyles.bfpDatePickerCalendar}
                          className={`${
                            redirection
                              ? appStyles.bfRedirectionDatePicker
                              : appStyles.bfpDatePicker
                          } ${appStyles.bfpDatePickerCalendarStartDate}`}
                          value={values.start_date}
                          format={appConstants.datePickerFormat}
                          minDate={new Date()}
                          maxDate={addDays(new Date(), 365)}
                          dayPlaceholder='dd'
                          monthPlaceholder='mm'
                          yearPlaceholder='yyyy'
                          id='start_date'
                          onChange={(value: any) => {
                            setFieldValue("start_date", value);
                            if (!values?.end_date || value > values.end_date) {
                              setFieldValue("end_date", addDays(value, 1));
                            }
                          }}
                          onBlur={handleBlur("start_date")}
                          calendarIcon={() => {
                            return <SvgIcon icon='calendar-icon' />;
                          }}
                          clearIcon={() => {
                            return <SvgIcon icon='clear-icon' />;
                          }}
                        />
                        {touched?.start_date && errors?.start_date && (
                          <div className={appStyles.formError}>This field is required</div>
                        )}
                      </div>
                    </div>

                    <div className={appStyles.formBlock}>
                      <div
                        className={`${
                          redirection ? appStyles.bfRedirectionFormField : appStyles.formField
                        } ${touched?.end_date && errors?.end_date ? appStyles.hasError : ""}`}
                      >
                        {!redirection ? (
                          <label className={appStyles.helperText}>End Date</label>
                        ) : null}
                        <DatePicker
                          calendarClassName={appStyles.bfpDatePickerCalendar}
                          className={`${
                            redirection
                              ? appStyles.bfRedirectionDatePicker
                              : appStyles.bfpDatePicker
                          } ${appStyles.bfpDatePickerCalendarEndDate}`}
                          format={appConstants.datePickerFormat}
                          value={values.end_date}
                          minDate={addDays(values.start_date || new Date(), 1)}
                          maxDate={addDays(values.start_date || new Date(), 364)}
                          dayPlaceholder='dd'
                          monthPlaceholder='mm'
                          yearPlaceholder='yyyy'
                          id='end_date'
                          onChange={(value) => {
                            setFieldValue("end_date", value);
                          }}
                          onBlur={handleBlur("end_date")}
                          calendarIcon={() => {
                            return <SvgIcon icon='calendar-icon' />;
                          }}
                          clearIcon={() => {
                            return <SvgIcon icon='clear-icon' />;
                          }}
                        />
                        {touched?.end_date && errors?.end_date && (
                          <div className={appStyles.formError}>This field is required</div>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className={appStyles.formRow}>
                    <div className={appStyles.formBlock}>
                      <label className={`${appStyles.labelStyle} ${appStyles.questionWrap}`}>
                        Countries or regions you are travelling to?
                        <TooltipIcon text={"Please select"}></TooltipIcon>
                      </label>

                      <div className={appStyles.formRow}>
                        <div
                          className={`${
                            redirection ? appStyles.bfRedirectionFormField : appStyles.formField
                          } ${
                            touched?.destinations && errors?.destinations ? appStyles.hasError : ""
                          }`}
                        >
                          <Select
                            defaultValue={values.destinations}
                            isMulti={true}
                            name='destinations'
                            id='destinations'
                            options={destinations}
                            isLoading={destinations?.length === 0 ? true : false}
                            classNames={{
                              control: selectControlStyling,
                              option: selectOptionControlStyling,
                            }}
                            onBlur={handleBlur("destinations")}
                            onChange={(newValue) => {
                              setFieldValue("destinations", newValue);
                            }}
                            value={values.destinations}
                            getOptionValue={(option: any) => option.code}
                            getOptionLabel={(option: any) => option.name}
                            isOptionDisabled={(option: any) => option.is_disabled}
                            blurInputOnSelect={false}
                          />
                          {touched?.destinations && errors?.destinations && (
                            <div className={appStyles.formError}>This field is required</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className={appStyles.formRow}>
                    <div className={appStyles.formBlock}>
                      <label className={`${appStyles.labelStyle} ${appStyles.questionWrap}`}>
                        Date of Birth of Traveller(s) :
                        <TooltipIcon text={"For lead applicant"}></TooltipIcon>
                      </label>
                      <div className={`${appStyles.formRow}`}>
                        <div
                          className={`${
                            redirection ? appStyles.bfRedirectionFormField : appStyles.formField
                          } ${
                            touched?.main_applicant && errors?.main_applicant
                              ? appStyles.hasError
                              : ""
                          }`}
                        >
                          <DatePicker
                            calendarClassName={appStyles.bfpDatePickerCalendar}
                            className={`${
                              redirection
                                ? appStyles.bfRedirectionDatePicker
                                : appStyles.bfpDatePicker
                            }`}
                            format={appConstants.datePickerFormat}
                            value={values.main_applicant}
                            dayPlaceholder='dd'
                            monthPlaceholder='mm'
                            yearPlaceholder='yyyy'
                            id='main_applicant'
                            maxDate={new Date()}
                            onChange={(value) => {
                              setFieldValue("main_applicant", value);
                            }}
                            calendarIcon={() => {
                              return <SvgIcon icon='calender-icon' />;
                            }}
                            clearIcon={() => {
                              return <SvgIcon icon='clear-icon' />;
                            }}
                            disableCalendar={true}
                          />
                          {touched?.main_applicant && errors?.main_applicant && (
                            <div className={appStyles.formError}>{errors?.main_applicant}</div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  {values.other_applicants.map((applicant: Date | null, index: number) => {
                    const hasError =
                      errors?.other_applicants &&
                      errors?.other_applicants[index] &&
                      touched?.other_applicants;

                    return (
                      <div key={index} className={appStyles.formRow}>
                        <div className={appStyles.formBlock}>
                          <div className={appStyles.travellerListFormBlock} key={index}>
                            <div className={appStyles.formRow}>
                              <div
                                className={`${appStyles.formField} ${appStyles.additionalApplicantLabel}`}
                              >
                                <label
                                  className={`${appStyles.labelStyle} ${appStyles.questionWrap}`}
                                >
                                  Traveller {index + 2}
                                </label>
                              </div>
                              <div
                                className={`${appStyles.formField} ${
                                  appStyles.formFieldHasCloseButton
                                } ${hasError ? appStyles.hasError : ""}`}
                              >
                                <DatePicker
                                  calendarClassName={appStyles.bfpDatePickerCalendar}
                                  className={`${
                                    redirection
                                      ? appStyles.bfRedirectionDatePicker
                                      : appStyles.bfpDatePicker
                                  }`}
                                  format={appConstants.datePickerFormat}
                                  value={values.other_applicants[index]}
                                  dayPlaceholder='dd'
                                  monthPlaceholder='mm'
                                  yearPlaceholder='yyyy'
                                  maxDate={new Date()}
                                  onChange={(value: any) => {
                                    let _other_applicants = values.other_applicants.concat();
                                    _other_applicants[index] = value;
                                    setFieldValue("other_applicants", _other_applicants);
                                  }}
                                  calendarIcon={() => {
                                    return <SvgIcon icon='calendar-icon' />;
                                  }}
                                  clearIcon={() => {
                                    return <SvgIcon icon='clear-icon' />;
                                  }}
                                  disableCalendar={true}
                                />
                                <span
                                  className={appStyles.btnDelete}
                                  onClick={(e) => {
                                    e.preventDefault();
                                    let _other_applicants = values.other_applicants.concat();

                                    _other_applicants.splice(index, 1);
                                    setFieldValue("other_applicants", _other_applicants);
                                    const _quoteData: ISingleTripQuoteData = {
                                      ...quoteData,
                                    };
                                    _quoteData.other_applicants?.splice(index, 1);
                                    _quoteData.all_applicants?.splice(index + 1, 1);
                                    setQuoteData(_quoteData);
                                  }}
                                >
                                  <SvgIcon icon='clear-circle-icon' />
                                </span>
                                {hasError ? (
                                  <div className={appStyles.formError}>
                                    {errors?.other_applicants
                                      ? errors?.other_applicants[index]
                                      : ""}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}

                  <div className={`${appStyles.formRow}`}>
                    <div className={appStyles.buttonBlock}>
                      <button
                        className={`${appStyles.bfButton} ${
                          redirection
                            ? `${appStyles.bfRedirectionButton} ${appStyles.bfRedirectionButtonOutline}`
                            : `${appStyles.bfButtonOutline} `
                        }
                       ${appStyles.bfbuttonIconBefore} ${appStyles.addTravellerBtn} ${
                          appStyles.mT10
                        } ${appStyles.bfbuttonHasIcon}`}
                        type='button'
                        disabled={values.other_applicants.length > 8 ? true : false}
                        onClick={() => {
                          let _other_applicants = values.other_applicants.concat();
                          _other_applicants.push(null);
                          setFieldValue("other_applicants", _other_applicants);
                        }}
                      >
                        <span className={`${appStyles.bfButtonIcon} ${appStyles.bfButtonIconPrev}`}>
                          <SvgIcon icon='add-traveller-icon' iconWidth={18} />
                        </span>
                        {redirection ? "Add More" : "Add traveller"}
                      </button>
                    </div>
                  </div>
                </div>

                {redirection && (
                  <div className={appStyles.formRow}>
                    <div className={`${appStyles.buttonBlock}  ${appStyles.buttonBlockNextPrev} $`}>
                      <button
                        className={`${appStyles.bfButton} ${appStyles.bfRedirectionButton} ${appStyles.w100} ${appStyles.bfButtonPrimary} ${appStyles.bfbuttonHasIcon} ${appStyles.bfbuttonIconAfter} ${appStyles.mT10} ${appStyles.w100}`}
                        type='submit'
                      >
                        See your price & customise
                        <span className={`${appStyles.bfButtonIcon} ${appStyles.bfButtonIconNext}`}>
                          <SvgIcon icon='forward-arrow-icon' />
                        </span>
                      </button>
                    </div>
                  </div>
                )}
              </div>

              {!redirection && (
                <div className={appStyles.formRow}>
                  <div
                    className={`${appStyles.buttonBlock}  ${appStyles.buttonBlockNextPrev} ${appStyles.buttonBlockAlignEnd}`}
                  >
                    <button
                      className={`${appStyles.bfButton} ${appStyles.bfButtonSecondary} ${appStyles.bfbuttonHasIcon} ${appStyles.bfbuttonIconAfter} ${appStyles.mT10}`}
                      type='submit'
                      disabled={isSubmitting}
                    >
                      Get Quote
                      <span className={`${appStyles.bfButtonIcon} ${appStyles.bfButtonIconNext}`}>
                        <SvgIcon icon='forward-arrow-icon' />
                      </span>
                    </button>
                  </div>
                </div>
              )}

              {hasError && <ShowApiError />}
              {!redirection && <Footer />}
            </form>
          )}
        </Formik>
      </div>
    </>
  );
};
export default QuoteForm;
